import CountryBaseController from "./country_base_controller";
import "@andypf/json-viewer";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

const mkLoadingSpinner = ({ size, color } = { size: 5, color: "teal" }) => `
<svg class="animate-spin h-${size} w-${size} text-${color}-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>
`;

const mkCheckIcon = ({ size, color } = { size: 5, color: "teal" }) => `
<svg class="h-${size} w-${size} text-${color}-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
</svg>
`;

const mkErrorIcon = ({ size, color } = { size: 5, color: "red" }) => `
<svg class="h-${size} w-${size} text-${color}-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
</svg>
`;

const buttonLoadingContent = `${mkLoadingSpinner({ color: "white", size: 5 })} <span class="ml-3">Processing...</span>`;

const buttonContent = `<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.56057 15.3182C4.2175 15.3182 3.07197 14.8516 2.12397 13.9182C1.17597 12.9848 0.701965 11.8411 0.701965 10.4871C0.701965 9.27902 1.09827 8.21699 1.89089 7.30097C2.68352 6.38496 3.65804 5.84907 4.81444 5.6933C5.11202 4.22603 5.84125 3.02396 7.00211 2.08707C8.16296 1.15021 9.49559 0.681774 11 0.681774C12.7678 0.681774 14.2662 1.29741 15.4951 2.52867C16.724 3.75994 17.3384 5.25295 17.3384 7.0077V7.5808H17.6461C18.6731 7.62247 19.5384 8.01966 20.2423 8.77237C20.9461 9.52511 21.298 10.4126 21.298 11.4348C21.298 12.5159 20.9252 13.4334 20.1795 14.1874C19.4339 14.9413 18.5243 15.3182 17.451 15.3182H12.0096C11.5322 15.3182 11.1285 15.1523 10.7982 14.8203C10.468 14.4884 10.3029 14.0855 10.3029 13.6115V8.10187L8.44036 9.94133L7.45964 8.9702L11 5.41732L14.5528 8.9702L13.5721 9.94133L11.7019 8.10187V13.6115C11.7019 13.6884 11.7331 13.759 11.7957 13.8231C11.8582 13.8872 11.9295 13.9192 12.0096 13.9192H17.4309C18.121 13.9192 18.7048 13.6813 19.1825 13.2053C19.6602 12.7294 19.899 12.1429 19.899 11.4457C19.899 10.7498 19.6598 10.1652 19.1813 9.69162C18.7029 9.21811 18.1172 8.98135 17.4243 8.98135H15.9394V7.0202C15.9394 5.65362 15.4579 4.48873 14.4949 3.52555C13.5318 2.56237 12.3672 2.08077 11.0008 2.08077C9.63448 2.08077 8.46976 2.56237 7.50664 3.52555C6.54352 4.48873 6.06197 5.65362 6.06197 7.0202H5.54454C4.60331 7.0202 3.79407 7.35385 3.11682 8.02115C2.43957 8.68847 2.10094 9.50129 2.10094 10.4596C2.10094 11.418 2.43797 12.2341 3.11202 12.9082C3.78605 13.5822 4.60223 13.9192 5.56057 13.9192H8.15384V15.3182H5.56057Z" fill="#FEFEFE"/>
</svg>
Upload`;

function poll(fn, timeout = 90000, interval = 2000) {
  const endTime = Number(new Date()) + timeout;

  async function checkCondition(resolve, reject) {
    try {
      const result = await fn();
      if (result) {
        resolve(result);
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("request timed out"));
      }
    } catch (error) {
      reject(error);
    }
  }
  return new Promise(checkCondition);
}

const BL_JOB_ID = "palantir_bl_job_id";
const COM_INVOICE_JOB_ID = "palantir_com_invoice_job_id";

export default class extends CountryBaseController {
  static targets = [
    "modal",
    "blFileInput",
    "ciFileInput",
    "tabs",
    "clearBlFileBtn",
    "clearCiFileBtn",
    "submitBtn",
    "fillBtn",
    "ciFileInputStatus",
    "blFileInputStatus",
  ];
  static values = {
    apikey: String,
    clientid: String,
    apiurl: String,
    dataBL: Object,
    dataComInvoice: Object,
    countryName: String,
  };

  connect() {
    console.log("AI Modal Controller Connected");
    this.submitBtnTarget.innerHTML = buttonContent;
    this.cn = this.countryNameValue;
    this.palantirBLJobId = this.getPalantirBLJobId();
    this.palantirComInvoiceJobId = this.getPalantirComInvoiceJobId();
    this.appendJobIdToForm(BL_JOB_ID, this.palantirBLJobId);
    this.appendJobIdToForm(COM_INVOICE_JOB_ID, this.palantirComInvoiceJobId);
    this.tabs();
  }

  getPalantirBLJobId() {
    const path = location.pathname;
    return path.endsWith("/new") ? null : sessionStorage.getItem(BL_JOB_ID);
  }

  getPalantirComInvoiceJobId() {
    const path = location.pathname;
    return path.endsWith("/new")
      ? null
      : sessionStorage.getItem(COM_INVOICE_JOB_ID);
  }

  renderPdf(uint8Array, type = "bl") {
    if (!uint8Array || uint8Array.length === 0) {
      document.getElementById(`pdf-frame-${type}`).src = "";
      return;
    }
    const tempBlob = new Blob([uint8Array], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(tempBlob);
    document.getElementById(`pdf-frame-${type}`).src = docUrl;
  }

  tabs() {
    const tabButtons = document.querySelectorAll(".tab-btn");
    const tabs = document.querySelectorAll(".tab-content");

    tabButtons[0].classList.toggle("selected", true);
    tabButtons[0].classList.toggle("bg-teal-500", true);
    tabButtons[0].classList.toggle("text-white", true);
    tabButtons[1].classList.toggle("text-teal-500", true);
    tabs[0].classList.toggle("hidden", false);

    tabButtons.forEach((tabButton) => {
      tabButton.addEventListener("click", (e) => {
        Array.from(e.target.parentNode.children).forEach((tabBtn) => {
          tabBtn.classList.toggle("selected", false);
          tabBtn.classList.toggle("bg-teal-500", false);
          tabBtn.classList.toggle("text-white", false);
          tabBtn.classList.toggle("text-teal-500", true);
        });

        e.target.classList.toggle("selected", true);
        e.target.classList.toggle("bg-teal-500", true);
        e.target.classList.toggle("text-white", true);

        const selectedTabId = e.target.dataset.tabContentId;
        const selectedTab = document.getElementById(selectedTabId);

        Array.from(selectedTab.parentNode.children).forEach((tab) => {
          tab.classList.toggle("hidden", true);
        });

        selectedTab.classList.toggle("hidden", false);
      });
    });
  }

  blFileChanged(event) {
    const file = event.target.files[0];
    if (file) {
      this.enableButton(this.submitBtnTarget);
      this.renderPdf(file, "bl");
      this.tabsTarget.classList.remove("hidden");
      this.tabsTarget.classList.add("flex");
      this.clearBlFileBtnTarget.classList.remove("opacity-40");
    } else {
      this.clearBlFile();
    }
  }

  ciFileChanged(event) {
    const file = event.target.files[0];
    if (file) {
      this.enableButton(this.submitBtnTarget);
      this.renderPdf(file, "commercial-invoice");
      this.tabsTarget.classList.remove("hidden");
      this.tabsTarget.classList.add("flex");
      this.clearCiFileBtnTarget.classList.remove("opacity-40");
    } else {
      this.clearCiFile();
    }
  }

  clearBlFile() {
    this.renderPdf(new Uint8Array(0), "bl");
    this.blFileInputTarget.value = null;
    if (!this.ciFileInputTarget?.files[0]) {
      this.disableButton(this.submitBtnTarget);
      this.tabsTarget.classList.add("hidden");
    }
    this.clearBlFileBtnTarget.classList.add("opacity-40");
    this.submitBtnTarget.classList.remove("hidden");
  }

  clearCiFile() {
    this.ciFileInputTarget.value = null;
    this.renderPdf(new Uint8Array(0), "commercial-invoice");
    if (!this.blFileInputTarget?.files[0]) {
      this.disableButton(this.submitBtnTarget);
      this.tabsTarget.classList.add("hidden");
    }
    this.clearCiFileBtnTarget.classList.add("opacity-40");
    this.submitBtnTarget.classList.remove("hidden");
  }

  submit() {
    const promises = [];
    if (this.blFileInputTarget.files[0]) {
      promises.push(this.submitDocument(this.blFileInputTarget, "bl"));
    }
    if (this.ciFileInputTarget.files[0]) {
      promises.push(
        this.submitDocument(this.ciFileInputTarget, "commercial-invoice"),
      );
    }
    if (promises.length > 0) {
      this.submitBtnTarget.innerHTML = buttonLoadingContent;
      this.submitBtnTarget.disabled = true;
      Promise.all(promises).then(() => {
        this.submitBtnTarget.classList.add("hidden");
        this.submitBtnTarget.innerHTML = buttonContent;
        this.enableButton(this.submitBtnTarget);
        this.submitBtnTarget.disabled = false;
      });
    }
  }

  appendJobIdToForm(key, jobId = this.getPalantirBLJobId()) {
    window.sessionStorage.setItem(key, jobId);
    if (!jobId) return;

    const form = document.querySelector(
      `form#new_${this.countryNameValue}_entry`,
    );
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = jobId;
    form?.appendChild(input);
  }

  async submitDocument(fileInput, type = "bl") {
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);
    formData.append("country", this.countryNameValue);
    let key = BL_JOB_ID;
    if (type === "bl") {
      key = BL_JOB_ID;
      this.blFileInputStatusTarget.innerHTML = mkLoadingSpinner({
        size: 5,
        color: "blue",
      });
      this.disableButton(this.clearBlFileBtnTarget);
    } else if (type === "commercial-invoice") {
      key = COM_INVOICE_JOB_ID;
      this.ciFileInputStatusTarget.innerHTML = mkLoadingSpinner({
        size: 5,
        color: "blue",
      });
      this.disableButton(this.clearCiFileBtnTarget);
    }
    return fetch(`${this.apiurlValue}/api/parse/${type}`, {
      method: "POST",
      headers: {
        "X-API-KEY": this.apikeyValue,
        "X-CLIENT-ID": this.clientidValue,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          if (type === "bl") {
            this.blFileInputStatusTarget.innerHTML = mkErrorIcon();
            this.enableButton(this.clearBlFileBtnTarget);
          } else if (type === "commercial-invoice") {
            this.ciFileInputStatusTarget.innerHTML = mkErrorIcon();
            this.enableButton(this.clearCiFileBtnTarget);
          }
          console.log("Palantir Error: ", response);
          throw new Error("Palantir Server Error. Check the logs...");
        } else return response.json();
      })
      .then(async ({ data, jobId, status }) => {
        this.appendJobIdToForm(key, jobId);
        if (!data && status?.toLowerCase() === "in_progress") {
          const jobStatus = await poll(async () => {
            return fetch(`${this.apiurlValue}/api/jobs/${jobId}/status`, {
              method: "GET",
              headers: {
                "X-API-KEY": this.apikeyValue,
                "X-CLIENT-ID": this.clientidValue,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.status?.toLowerCase() === "completed" && data.data) {
                  return data;
                } else {
                  return null;
                }
              })
              .catch((err) => {
                console.error(err);
                return null;
              });
          });
          if (type === "bl") {
            this.blFileInputStatusTarget.innerHTML = mkCheckIcon();
            this.dataBLValue = jobStatus.data;
            this.enableButton(this.clearBlFileBtnTarget);
            this.fillTheForm();
          } else if (type === "commercial-invoice") {
            this.ciFileInputStatusTarget.innerHTML = mkCheckIcon();
            this.enableButton(this.clearCiFileBtnTarget);
            this.dataComInvoiceValue = jobStatus.data;
            this.fillInvoiceDetails();
          }
        }
      })
      .catch((error) => {
        alert(
          `An error occurred while processing the file: \n${String(error.message)}`,
        );
      });
  }

  disableButton(target) {
    target.classList.add("disabled");
    target.classList.add("opacity-40");
    target.setAttribute("disabled", "disabled");
  }

  enableButton(target) {
    target.classList.remove("disabled");
    target.classList.remove("opacity-40");
    target.removeAttribute("disabled");
  }

  makeAiIcon() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("flex", "items-center", "justify-end", "flex-1");
    wrapper.setAttribute("palantir-type", "tippy-wrapper");
    const aiIcon = document.createElement("i");
    aiIcon.classList.add("material-icons", "text-teal-500", "cursor-pointer");
    aiIcon.style.textAlign = "right";
    aiIcon.style.fontSize = "1rem";
    aiIcon.textContent = "auto_awesome";
    wrapper.appendChild(aiIcon);
    return { wrapper, aiIcon };
  }

  makePopupContent(value) {
    const wrapper = document.createElement("div");
    const icon = document.createElement("i");
    icon.classList.add(
      "material-icons",
      "text-teal-500",
      "cursor-pointer",
      "ml-2",
    );
    icon.style.textAlign = "right";
    icon.style.fontSize = "1rem";
    icon.textContent = "content_copy";
    icon.style.userSelect = "none";
    wrapper.textContent = value;
    wrapper.appendChild(icon);

    return [wrapper, icon];
  }

  manageInput(selector, data, parent = null) {
    const { wrapper, aiIcon } = this.makeAiIcon();

    const target = (parent || document).querySelector(selector);
    if (!target || !data) {
      return;
    }
    const select = target.slim;
    const value =
      typeof data === "object"
        ? "sanitized" in data
          ? data.sanitized
          : data.value
        : data;

    target.value = value ?? data.default ?? "";

    if (value === undefined) {
      target.classList.add("border-red-500");
      return;
    }

    if (select) {
      select.search(value);
      setTimeout(() => {
        const v = select
          .getData()
          .find((x) => x.text.toLowerCase() === value.toLowerCase());
        if (v) select.setSelected(v.value);
      }, 2000);
    }

    if (
      (target.tagName === "SELECT" || target.nodeName === "SELECT") &&
      !select
    ) {
      // regular select. search among the labels and set the value
      const options = target.querySelectorAll("option");
      options.forEach((option) => {
        if (option.text.toLowerCase() === value.toLowerCase()) {
          option.selected = true;
        }
      });
    }

    if (target.getAttribute("data-datepicker-target")) {
      flatpickr(target, {
        dateFormat: "d-m-Y",
        defaultDate: typeof value === "string" ? new Date(value) : value,
      });
    }

    // Add AI Icon next to the label
    const label = (
      !!select ? target.parentNode.parentNode : target.parentNode
    ).querySelector(!!select ? "legend" : "label");
    label?.classList.add("flex");
    label?.querySelector("[palantir-type=tippy-wrapper]")?.remove();
    label?.appendChild(wrapper);

    // crate a popup content for the AI Icon
    const [popupContent, icon] = this.makePopupContent(value);
    icon.addEventListener("click", (e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(value);
      e.target.textContent = "check_circle_outline";
      setTimeout(() => {
        e.target.textContent = "content_copy";
      }, 1000);
    });

    tippy(aiIcon, {
      content: popupContent,
      trigger: "click",
      allowHTML: true,
      interactive: true,
      inertia: true,
    });

    const borderTarget = !!select ? target.parentNode : target;
    borderTarget.classList.add("border", "rounded");
    // Add border color based on confidence
    if (data.confidence === 1.0) {
      borderTarget.classList.add("border-green-500");
    } else if (data.confidence < 1.0 && data.confidence >= 0.75) {
      borderTarget.classList.add("border-lime-500");
    } else if (data.confidence < 0.75 && data.confidence >= 0.5) {
      borderTarget.classList.add("border-yellow-500");
    } else if (data.confidence < 0.5 && data.confidence > 0.1) {
      borderTarget.classList.add("border-yellow-700");
    } else {
      borderTarget.classList.add("border-red-500");
    }

    target.addEventListener("input", function() {
      borderTarget.classList.remove(
        "border-red-500",
        "border-green-500",
        "border-yellow-500",
        "border-yellow-700",
        "border-lime-500",
      );
    });
  }

  fillTheForm() {
    this.fillBlNumber();
    this.fillShipperSection();
    this.fillConsigneeSection();
    this.fillNotifySection();
    this.fillBLSection();
    this.fillShipmentDetails();
  }

  fillBlNumber() {
    this.manageInput(
      `#${this.cn}_entry_bl_number`,
      this.dataBLValue.bill_of_lading_number,
    );
  }

  fillInvoiceDetails() {
    this.manageInput(
      `#${this.cn}_entry_incoterm`,
      this.dataComInvoiceValue.incoterm,
    );
    this.manageInput(
      `#${this.cn}_entry_currency`,
      this.dataComInvoiceValue.currency,
    );
    if (this.cn === "somalia" || this.cn === "djibouti") {
      this.manageInput(
        `#${this.cn}_entry_currency_of_goods`,
        this.dataComInvoiceValue.currency_of_goods,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_insurance`,
        this.dataComInvoiceValue.currency_of_insurance,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_freight`,
        this.dataComInvoiceValue.currency_of_freight,
      );
      this.manageInput(
        `#${this.cn}_entry_currency_of_other_charges`,
        this.dataComInvoiceValue.currency_of_other_charges,
      );
    }
    this.manageInput(
      `#${this.cn}_entry_fob_value`,
      this.dataComInvoiceValue.fob_value,
    );
    this.manageInput(
      `#${this.cn}_entry_invoice_value`,
      this.dataComInvoiceValue.invoice_value,
    );
    this.manageInput(
      `#${this.cn}_entry_insurance_value`,
      this.dataComInvoiceValue.insurance_value,
    );
    this.manageInput(
      `#${this.cn}_entry_ocean_freight`,
      this.dataComInvoiceValue.ocean_freight,
    );
  }

  fillShipperSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(`#${this.cn}_entry_shipper_attributes_name`, {
        value: `${this.dataBLValue.shipper_name.value} ${this.dataBLValue.shipper_address.sanitized}`,
      });
    } else {
      this.manageInput(
        `#${this.cn}_entry_shipper_attributes_name`,
        this.dataBLValue.shipper_name,
      );
    }
    this.manageInput(`#${this.cn}_entry_shipper_attributes_email`, {
      ...this.dataBLValue.shipper_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_shipper_attributes_phone`, {
      ...this.dataBLValue.shipper_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_shipper_attributes_address`,
      this.dataBLValue.shipper_address,
    );
    const shipperEl = this.findInputId(
      `${this.cn}_entry_shipper_attributes_country`,
      "shipper_country",
    );
    this.manageInput(`#${shipperEl}`, this.dataBLValue.shipper_country);
    setTimeout(() => {
      this.manageInput(
        `#${this.findInputId(`${this.cn}_entry_shipper_attributes_city`)}`,
        this.dataBLValue.shipper_city,
      );
    }, 2000);
  }

  fillConsigneeSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(`#${this.cn}_entry_consignee_attributes_name`, {
        value: `${this.dataBLValue.consignee_name.value} ${this.dataBLValue.consignee_address.sanitized}`,
      });
    } else {
      this.manageInput(
        `#${this.cn}_entry_consignee_attributes_name`,
        this.dataBLValue.consignee_name,
      );
    }
    this.manageInput(`#${this.cn}_entry_consignee_attributes_email`, {
      ...this.dataBLValue.consignee_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_consignee_attributes_phone`, {
      ...this.dataBLValue.consignee_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_consignee_attributes_address`,
      this.dataBLValue.consignee_address,
    );
    this.manageInput(
      `#${this.findInputId(`${this.cn}_entry_consignee_attributes_city`)}`,
      this.dataBLValue.consignee_city,
    );

    const countryEl = this.findInputId(
      `${this.cn}_entry_consignee_attributes_country`,
      "consignee_country",
    );
    this.manageInput(`#${countryEl}`, this.dataBLValue.consignee_country);
  }

  fillNotifySection() {
    this.manageInput(
      `#${this.cn}_entry_notify_attributes_name`,
      this.dataBLValue.notify_name,
    );
    this.manageInput(`#${this.cn}_entry_notify_attributes_email`, {
      ...this.dataBLValue.notify_email,
      default: "noreply@example.com",
    });
    this.manageInput(`#${this.cn}_entry_notify_attributes_phone`, {
      ...this.dataBLValue.notify_phone,
      default: "0",
    });
    this.manageInput(
      `#${this.cn}_entry_notify_attributes_address`,
      this.dataBLValue.notify_address,
    );
    const notifyEl = this.findInputId(
      `${this.cn}_entry_notify_attributes_country`,
      "notify_country",
    );
    this.manageInput(`#${notifyEl}`, this.dataBLValue.notify_country);
    setTimeout(() => {
      this.manageInput(
        `#${this.findInputId(`${this.cn}_entry_notify_attributes_city`)}`,
        this.dataBLValue.notify_city,
      );
    }, 2000);
  }

  fillBLSection() {
    if (this.cn === "sierra_leone") {
      this.manageInput(
        `#${this.cn}_entry_booking_number`,
        this.dataBLValue.bill_of_lading_number,
      );
    }
    this.manageInput(
      `#${this.cn}_entry_voyage_number`,
      this.dataBLValue.voyage_number,
    );
    this.manageInput(
      `#${this.cn}_entry_sgs_number`,
      this.dataBLValue.sgs_number,
    );
    this.manageInput(
      `#${this.cn}_entry_transaction_number`,
      this.dataBLValue.transaction_number,
    );
    this.manageInput(
      `#${this.cn}_entry_taxpayer_number`,
      this.dataBLValue.taxpayer_number,
    );
    this.manageInput(
      this.cn === "sierra_leone"
        ? `${this.cn}_entry_loading_country`
        : "#country_of_loading",
      this.dataBLValue.country_of_loading,
    );

    this.manageInput(
      this.cn === "sierra_leone"
        ? `#${this.cn}_entry_loding_city`
        : `#${this.cn}_entry_port_of_loading_id`,
      this.dataBLValue.port_of_loading,
    );
    this.manageInput(
      this.cn === "sierra_leone"
        ? `#${this.cn}_entry_discharge_city`
        : `#${this.cn}_entry_port_of_discharge_id`,
      this.dataBLValue.port_of_discharge,
    );

    this.manageInput(
      this.cn === "sierra_leone"
        ? `#${this.cn}_entry_vessel`
        : `#${this.cn}_entry_vessel_id`,
      this.dataBLValue.vessel,
    );

    this.manageInput(
      `#${this.cn}_entry_final_destination`,
      this.dataBLValue.place_of_delivery || this.dataBLValue.consignee_city,
    );

    this.manageInput(`#${this.cn}_entry_eta`, {
      value: new Date(
        new Date().setMonth(new Date().getMonth() + 1),
      ).toISOString(),
    });

    this.manageInput(
      `#${this.cn}_entry_line_id`,
      this.dataBLValue.shipping_line,
    );

    this.manageInput(
      `#${this.cn}_entry_carrier_id`,
      this.dataBLValue.shipping_line,
    );
  }

  fillShipmentDetails() {
    this.manageInput(`#${this.cn}_entry_volume`, {
      ...this.dataBLValue.total_measurement,
      default: 0,
    });
    this.manageInput(
      `#${this.cn}_entry_description_of_goods`,
      this.dataBLValue.description_of_goods ||
      this.dataBLValue.containers?.[0]?.description_of_goods,
    );
    this.manageInput(
      `#${this.cn}_entry_total_gross_weight`,
      this.dataBLValue.total_weight,
    );
    this.manageInput(
      `#${this.cn}_entry_number_of_packages`,
      this.dataBLValue.number_of_packages,
    );

    const shipmentType = this.predictShipmentType(this.dataBLValue);
    this.manageInput("#shipment_type", {
      value: shipmentType,
    });

    this.manageInput(`#${this.cn}_entry_freight_payment`, {
      ...(this.dataBLValue.freight_payment || { value: "Prepaid" }),
      default: "Prepaid",
    });

    setTimeout(() => {
      const addBtn = document.querySelector(
        "[data-shipment-target=box] [data-palantir-type=add-row]",
      );
      if (!addBtn) return;

      if (shipmentType === "container") {
        for (let i = 0; i < this.dataBLValue.containers.length; i++) {
          addBtn.click();
        }
        setTimeout(() => {
          document
            .querySelectorAll(
              "[data-shipment-target=box] [data-palantir-type=container-row]",
            )
            .forEach((box, i) => {
              this.manageInput(
                '[data-palantir-type="container_number"]',
                this.dataBLValue.containers[i].container_no,
                box,
              );
              this.manageInput(
                '[data-palantir-type="seal_number"]',
                this.dataBLValue.containers[i].seal_no,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_size"]',
                this.dataBLValue.containers[i].container_size,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_type"]',
                this.dataBLValue.containers[i].container_type,
                box,
              );
              this.manageInput(
                '[data-palantir-type="container_load_type"]',
                this.dataBLValue.containers[i].load_type,
                box,
              );
            });
        }, 500);
      } else if (shipmentType === "roro") {
        for (let i = 0; i < this.dataBLValue.roro?.length; i++) {
          addBtn.click();
        }
      } else {
        addBtn.click();
      }
    }, 500);
  }

  predictShipmentType(data) {
    if (data.containers?.length > 0) return "container";
    if (data.roro?.length > 0) return "roro";
    if (data.genco) return "genco";
    if (data.bulk) return "bulk";
    return "unknown";
  }

  findInputId(_selector, fallback = null) {
    const selector = _selector.replace("#", "");
    const dqs = document.getElementById.bind(document);
    if (dqs(selector)) return selector;
    else if (dqs(`${selector}_id`)) return `${selector}_id`;
    else return fallback;
  }
}
